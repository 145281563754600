export let Status = [
  {
      key: true,
      name: "ATIVO",
  },
  {
      key: false,
      name: "INATIVO",
  },
];

export function convertAtivo(status) {
  const value = Status.find((active) => active.key == status);
  return value.name;
}
<template>
  <div class="grid">
    <div class="col-12">
      <Panel header="Gerenciamento de Competências" style="height: 100%">
        <Toolbar class="p-mb-4">
          <template #start>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="mr-2"
              @click="showCreate"
            />
          </template>

          <template #end>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Buscar..."
                class="p-mr-2"
              />
            </span>
          </template>
        </Toolbar>
        <DataTable
          :ref="dt"
          :dataKey="id"
          class="p-datatable-sm"
          :value="list"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 30]"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :loading="loading"
          :totalRecords="totalRecords"
          currentPageReportTemplate="Registro {first} de {last} de um total de {totalRecords} competências."
        >
          <!-- <Column header="Ano" field="ano" sortable>
            <template #body="slotProps"> {{ slotProps.data.ano }} </template>
          </Column>
          <Column header="Mês" field="mes" sortable>
            <template #body="slotProps">
              {{ slotProps.data.mes }}
            </template>
          </Column>
          <Column header="Retificação" field="retificacao" sortable>
            <template #body="slotProps">
              {{ slotProps.data.retificacao }}
            </template>
          </Column> -->

          <Column header="Descrição" field="descricao" sortable>
            <template #body="slotProps">
              {{ slotProps.data.descricao }}
            </template>
          </Column>
          <Column header="Data Inicio" field="dtInicio" sortable>
            <template #body="slotProps">
              {{ this.$DateTime.formatarDateTime24hours(slotProps.data.dtInicio) }}
            </template>
          </Column>
          <Column header="Data Término" field="dtTermino" sortable>
            <template #body="slotProps">
              {{ this.$DateTime.formatarDateTime24hours(slotProps.data.dtTermino) }}
            </template>
          </Column>
          <Column header="Visualização" field="visualizacao" sortable>
            <template #body="slotProps">
              <span
                v-if="slotProps.data.visualizacao === true"
                :class="'user-badge status-' + slotProps.data.visualizacao"
                @click="changeStatus(slotProps.data)"
                v-tooltip.top="'Clique para INATIVAR'"
                style="cursor: pointer"
                >Ativo</span
              >
              <span
                v-if="slotProps.data.visualizacao === false"
                :class="'user-badge status-' + slotProps.data.visualizacao"
                @click="changeStatus(slotProps.data)"
                v-tooltip.top="'CLIQUE ATIVAR'"
                style="cursor: pointer"
                >Inativo</span
              >
            </template>
          </Column>

          <Column header="Base de Referência" field="baseRef" sortable>
            <template #body="slotProps">
              {{ slotProps.data.baseRef }}
            </template>
          </Column>

          <Column header="Ações">
            <template #body="slotProps">
              <div class="p-button-group">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-success mr-2"
                  @click="showUpdate(slotProps.data)"
                  v-tooltip.top="'CLIQUE PARA EDITAR'"
                />
                <Button
                  icon="pi pi-trash"
                  :disabled="slotProps.data.baseRef"
                  class="p-button-rounded p-button-warning mr-2"
                  @click="showRemove(slotProps.data)"
                  v-tooltip.top="'CLIQUE PARA EXCLUIR A COMPETENCIA'"
                />
                <Button
                  :disabled="slotProps.data.baseRef"
                  class="p-button-rounded p-button-info mr-2"
                  icon="pi pi-database "
                  @click="showGenerate(slotProps.data)"
                  v-tooltip.top="'CLIQUE PARA GERAR'"
                />
                <Button
                  :disabled="!slotProps.data.baseRef"
                  class="p-button-rounded p-button-danger"
                  icon="pi pi-times-circle"
                  @click="showRemove(slotProps.data)"
                  v-tooltip.top="
                    'CLIQUE PARA EXCLUIR TODOS OS LANÇAMENTOS DESSA COMPETÊNCIA'
                  "
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </Panel>
      <ConfirmDialog></ConfirmDialog>
      <dialog-form :objSelected="obj" @findAll="findAll" />
      <dialog-generate
        :objSelected="obj"
        :competenciasSelect="list"
        @findAll="findAll"
      />
    </div>
  </div>
</template>
  
<script>
//models
import Competencia from "../../../src/models/competencia";

//Services
import CompetenciaService from "../../../src/service/competencia/competencia_service.js";
import LancamentoService from "../../../src/service/lancamento/lancamento_service.js";

//components
import DialogForm from "./components/dialog-form.vue";
import DialogGenerate from "./components/dialog-generate.vue";

export default {
  components: {
    DialogForm,
    DialogGenerate,
  },
  data() {
    return {
      list: null,
      obj: new Competencia(),
      competenciaService: new CompetenciaService(),
      lancamentoService: new LancamentoService(),
      filters: {
        global: {
          value: null,
        },
      },
      loading: false,
      totalRecords: null,
    };
  },
  mounted() {
    this.findAll();
  },
  methods: {
    findAll() {
      this.competenciaService.findAll().then((data) => {
        this.list = data;
        this.loading = false;
      });
    },
    showCreate() {
      this.obj = new Competencia();
      this.$store.state.views.competencia.dialogForm = true;
    },
    showUpdate(data) {
      this.obj = {...data};
      this.$store.state.views.competencia.dialogForm = true;
    },
    showGenerate(data) {
      this.obj = data;
      this.$store.state.views.competencia.dialogGenerate = true;
    },

    showRemove(data) {
      if (data.baseRef === null) {
        this.remove(data);
      } else this.removeAll(data);
    },

    remove(data) {
      this.$confirm.require({
        message: "Deseja Excluir esse registro?",
        header: "Deseja deletar?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.competenciaService
            .delete(data.id)
            .then((data) => {
              this.$msgSuccess(data);
              this.findAll();
            })
            .catch((error) => {
              this.$msgErro(error);
            });
        },
      });
    },
    removeAll(data) {
      this.$confirm.require({
        message: `Deseja Excluir todos os registros de lançamento da competência ${data.descricao}`,
        header: "Deseja Excluir Registros de Lançamento?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.loading = true;
          this.lancamentoService
            .deleteByCompetenciaId(data.id)
            .then((data) => {
              this.$msgSuccess(data);
              this.findAll();
            })
            .catch((error) => {
              this.$msgErro(error);
            });
        },
      });
      this.loading = false;
    },

    changeStatus(user) {
      this.competenciaService
        .updateStatus(user)
        .then((data) => {
          this.$msgSuccess(data);
          this.findAll();
        })
        .catch((error) => {
          this.$msgErro(error);
          //console.error(error);
        });
    },
  },
};
</script>
  <style lang="scss" field="" scoped>
@import url("../../App.scss");
</style>
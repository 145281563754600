<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '600px' }"
    :header="dialogHeader"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="grid fluid">
      <!-- <div class="field col-12 md:col-12" style="text-align: center">
        <p>
          GERAR LANÇAMENTOS PARA A COMPETÊNCIA:
          <b>{{
            this.obj.ano + "/" + this.obj.mes + "-" + this.obj.retificacao
          }}</b>
        </p>
      </div> -->

      <div class="field col-12 md:col-12 text-center">
        <label for="referencia">Base de Referência</label>
        <div class="dropdown-container">
          <Dropdown
            v-model="obj.referencia"
            :options="competencias"
            filter
            optionLabel="descricao"
            optionValue="id"
            placeholder="Selecione"
            class="dropdown"
            :class="{ 'p-invalid': !obj.referencia && submitted }"
            required
          >
            <template #option="slotProps">
              {{ slotProps.option.descricao }}
            </template>
          </Dropdown>

          <small v-if="!obj.referencia && submitted == true" class="p-error"
            >Campo Obrigatório</small
          >
        </div>
      </div>
    </div>

    <ProgressBar v-if="submitted" mode="indeterminate" />

    <template #footer>
      <Button
        label="Gerar"
        class="p-button"
        icon="pi pi-check"
        @click="send(criarLancamento)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>

<script>
//SERVICE
import CompetenciaService from "../../../service/competencia/competencia_service";
import LancamentoService from "../../../service/lancamento/lancamento_service";

export default {
  props: ["objSelected", "competenciasSelect"],
  data() {
    return {
      obj: null,
      criarLancamento: {
        lancada: null,
        referencia: null,
      },
      competencias: [],
      dialogHeader: null,
      submitted: false,
      competenciaService: new CompetenciaService(),
      lancamentoService: new LancamentoService(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.competencia.dialogGenerate;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.competencia.dialogGenerate = value;
      },
    },
  },
  methods: {
    send() {
      this.submitted = true;
      this.criarLancamento = {
        lancada: this.obj.lancada,
        referencia: this.obj.referencia,
      };
      this.lancamentoService
        .criarLancamentos(this.criarLancamento)
        .then((data) => {
          if (data.status === 204) {
            this.$msgSuccess(data);
            this.hideDialog();
            this.submitted = false;
          }
        })
        .catch((error) => {
          this.$msgErro(error);
          this.submitted = false;
        });
    },
    getData() {
      this.obj = this.objSelected;
      this.competencias = this.competenciasSelect;
      this.obj.lancada = this.objSelected.id;
      this.dialogHeader = `GERAR LANÇAMENTOS PARA: ${this.obj.descricao} `;
    },
    hideDialog() {
      this.submitted = false;
      this.visibleDialog = false;
      this.$emit("findAll");
    },
  },
};
</script>

<style scoped>
.dropdown-container {
  max-width: 400px;
  margin: 0 auto;
}

.dropdown {
  width: 100%;
}
</style>
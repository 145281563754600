<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '800px' }"
    header="Fomulário de Competência"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="grid fluid">
      <div class="field col-12 md:col-6">
        <label for="ano">Ano</label>
        <InputText
          id="ano"
          type="number"
          v-model="v$.obj.ano.$model"
          placeholder="Digite o ano"
          :class="{ 'p-invalid': submitted && v$.obj.ano.$invalid }"
        />
        <small class="p-error" v-if="submitted && v$.obj.ano.$invalid"
          >Campo obrigatório.</small
        >
      </div>
      <div class="field col-12 md:col-6">
        <label for="mes">Mês</label>
        <InputText
          id="mes"
          v-model="v$.obj.mes.$model"
          placeholder="Digite o mês"
          :class="{ 'p-invalid': submitted && v$.obj.mes.$invalid }"
        />
        <small class="p-error" v-if="submitted && v$.obj.mes.$invalid"
          >Campo obrigatório.</small
        >
      </div>

      <div class="field col-12 md:col-3">
        <label for="dtInicio">Data Início</label>
        <!-- <Calendar
          id="calendar-24h"
          v-model="v$.obj.dtInicio.$model"
          :class="{ 'p-invalid': submitted && v$.obj.dtInicio.$invalid }"
          :showTime="true" :showSeconds="false" hourFormat="24"
        /> -->
        <InputText
          v-model="v$.obj.dtInicio.$model"
          type="datetime-local"
          :class="{ 'p-invalid': submitted && v$.obj.dtInicio.$invalid }"
        />
        <small class="p-error" v-if="submitted && v$.obj.dtInicio.$invalid"
          >Campo obrigatório.</small
        >
      </div>
      <div class="field col-12 md:col-3">
        <label for="dtTermino">Data Término</label>
        <!-- <Calendar
          id="calendar-24h"
          v-model="obj.dtTermino"
          :showTime="true" :showSeconds="false" hourFormat="24"
        /> -->
        <InputText v-model="obj.dtTermino" type="datetime-local" />
      </div>
      <div class="field col-12 md:col-6">
        <label for="visualizacao">Visualizacao</label>
        <Dropdown
          v-model="obj.visualizacao"
          :options="Status"
          :showClear="true"
          optionValue="key"
          optionLabel="name"
          placeholder="Selecione"
          id="visualizacao"
        />
      </div>
    </div>

    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.obj.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>

<script>
//MODELS
import Competencia from "../../../models/competencia";

//ENUMS
import { Status } from "../../../models/enums/status";

//SERVICES
import CompetenciaService from "../../../service/competencia/competencia_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["objSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      obj: new Competencia(),
      Status,
      submitted: false,
      service: new CompetenciaService(),
    };
  },
  validations() {
    return {
      obj: new Competencia().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.competencia.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.competencia.dialogForm = value;
      },
    },
  },
  mounted() {},
  methods: {
    send(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        this.obj.dtInicio = this.$DateTime.formatarDateInput(this.obj.dtInicio);
        this.obj.dtTermino = this.$DateTime.formatarDateInput(
          this.obj.dtTermino
        );
        if (this.obj.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.service
        .create(this.obj)
        .then((data) => {
          if (data.status === 201) {
            this.$msgSuccess(data);
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.service
        .update(this.obj)
        .then((data) => {
          if (data.status === 200) {
            this.$msgSuccess(data);
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    getData() {
      this.obj = this.objSelected;
      this.obj.dtInicio = this.$DateTime.formatarDataHora(this.objSelected.dtInicio)
      this.obj.dtTermino = this.$DateTime.formatarDataHora(this.objSelected.dtTermino)
    },
    hideDialog() {
      this.obj = new Competencia();
      this.submitted = false;
      this.visibleDialog = false;
      this.$emit("findAll");
    },
  },
};
</script>

<style>
</style>